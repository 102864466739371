/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import axios from "axios";
import * as zoneActions from "./types/zone";
import { axiosMaker, errorMessage } from "../utils/utils";

const company = "5e793bfe142fff7b1494b7e6";

export const getFormattedZones = (level, filterBy) => async (dispatch) => {
  dispatch({
    type: zoneActions.GET_FORMATTED_ZONES_SENDING,
    payload: {
      formattedZones: [],
      dataLength: 0,
    },
  });
  try {
    const response = await axios(
      axiosMaker(
        "POST",
        "zones/formattedZones",
        { level, filterBy, company },
        true
      )
    );
    return dispatch({
      type: zoneActions.GET_FORMATTED_ZONES_SUCCESS,
      payload: {
        formattedZones: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: zoneActions.GET_FORMATTED_ZONES_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const setCheckedSectors = (sectors) => (dispatch) => {
  dispatch({
    type: zoneActions.SET_CHECKED_SECTORS,
    payload: {
      checkedSectors: sectors,
    },
  });
};
