export const GET_WIZARD_SENDING = 'GET_WIZARD_SENDING';

export const GET_WIZARD_SUCCESS = 'GET_WIZARD_SUCCESS';

export const GET_WIZARD_FAILED = 'GET_WIZARD_FAILED';

export const CREATE_LEAD_SENDING = 'CREATE_LEAD_SENDING';

export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';

export const CREATE_LEAD_FAILED = 'CREATE_LEAD_FAILED';

export const ADD_PREFERENCE_SENDING = 'ADD_PREFERENCE_SENDING';

export const ADD_PREFERENCE_SUCCESS = 'ADD_PREFERENCE_SUCCESS';

export const ADD_PREFERENCE_FAILED = 'ADD_PREFERENCE_FAILED';

export const REGISTER_LEAD_SENDING = 'REGISTER_LEAD_SENDING';

export const REGISTER_LEAD_SUCCESS = 'REGISTER_LEAD_SUCCESS';

export const REGISTER_LEAD_FAILED = 'REGISTER_LEAD_FAILED';

export const WELCOME_EMAIL_SENDING = 'WELCOME_EMAIL_SENDING';

export const WELCOME_EMAIL_SUCCESS = 'WELCOME_EMAIL_SUCCESS';

export const WELCOME_EMAIL_FAILED = 'WELCOME_EMAIL_FAILED';

export const UPDATE_MATCHES_SENDING = 'UPDATE_MATCHES_SENDING';

export const UPDATE_MATCHES_SUCCESS = 'UPDATE_MATCHES_SUCCESS';

export const UPDATE_MATCHES_FAILED = 'UPDATE_MATCHES_FAILED';
