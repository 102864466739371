export const styles = (theme) => ({
  zonesPadding: {
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    "&.listPadding": {
      paddingLeft: theme.spacing.unit * 8,
      paddingRight: theme.spacing.unit * 8,
    },
    "@media (max-width: 768px)": {
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
    },
  },
  collapsableLevel: {
    "@media (max-width: 768px)": {
      paddingLeft: '4px !important',
    },
  }
});

export default styles;
