import React, {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Stars from '@material-ui/icons/Stars';
import StepLabel from '@material-ui/core/StepLabel';
import PropTypes from 'prop-types';
import {styles} from './styles';

export class StepperRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderStepIcon = this.renderStepIcon.bind(this);
  }

  renderStepIcon(props, activeStep, index) {
    const {classes, length} = this.props;
    if (index === length - 1) {
      return <Stars className={activeStep === index ? classes.check : classes.home} />;
    }
    if (activeStep !== 0 && activeStep === index && index !== length - 1) {
      return <CheckCircle className={classes.check} />;
    }
    return (
      <div />
    );
  }

  render() {
    const {activeStep, classes, length} = this.props;
    const steps = [];
    for (let i = 0; i < length; i += 1) {
      steps.push(i);
    }
    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine
        }}
      />
    );
    return (
      <Stepper activeStep={activeStep} connector={connector} className={classes.root}>
        {steps.map(index => (
          <Step 
            key={index} 
            classes={{
              horizontal: classes.horizontal
            }}
          >
            <StepLabel
              StepIconComponent={props => this.renderStepIcon(props, activeStep, index)}
              classes={{iconContainer: classes.iconContainer}}
            />
          </Step>
        ))}
      </Stepper>
    );      
  }
}
StepperRow.propTypes = {
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired
};

export default (withStyles(styles)(StepperRow));

/* <MobileStepper
  variant="progress"
  steps={length}
  position="static"
  activeStep={activeStep}
  classes={{root:classes.root,progress:classes.progress}}
/> */
