export const styles = theme => ({
  facebookButton: {
    background: '#3d5a96',
    borderRadius: 4,
    color: '#fff',
    fontSize: 14,
    fontFamily: 'Poppins',
    padding: 10,
    marginTop: 20,
    marginBottom: 20
  },
  submit: {
    marginTop: theme.spacing.unit * 5
  },
  input: {
    marginTop: theme.spacing.unit,
    maxWidth: 300

  }
});
