
export const styles = theme => ({
  group: {
    margin: theme.spacing.unit * 3
  },
  separator: {
    flex: '1 1 100%'
  },
  slider: {
    padding: '22px 0px'
  },
  toolbar: {
    paddingRight: 0,
    paddingLeft: 0
  },
  title: {
    display: 'table',
    margin: '0 auto'
  }
});
