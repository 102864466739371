import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {styles} from './styles';
import {getFormattedZones, setCheckedSectors} from '../../../actions/zone';
import {sortObjectByProperty} from '../../../utils/utils';

export class CollapsableZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCities: [],
      openedZones: []
    };
    this.handleCheckSector = this.handleCheckSector.bind(this);
    this.onCollapseCities = this.onCollapseCities.bind(this);
    this.onCollapseZones = this.onCollapseZones.bind(this);
  }

  componentWillMount() {
    this.props.getFormattedZones('cities', 'projects');
  }

  handleCheckSector(zone) {
    const action = !this.props.checkedSectors.includes(zone);
    const changes = action
      ? [...this.props.checkedSectors, zone]
      : this.props.checkedSectors.filter(item => item !== zone);

    this.props.onCheckSector(changes);
    this.props.setCheckedSectors(changes);
  }

  onCollapseCities(city) {
    const {openedCities} = this.state;

    if (openedCities.includes(city)) {
      this.setState({
        openedCities: openedCities.filter(item => item !== city)
      });
    } else this.setState({openedCities: [...openedCities, city]});
  }

  onCollapseZones(zone) {
    const {openedZones} = this.state;

    if (openedZones.includes(zone)) {
      this.setState({
        openedZones: openedZones.filter(item => item !== zone)
      });
    } else this.setState({openedZones: [...openedZones, zone]});
  }

  sortName(arr) { 
    return sortObjectByProperty(arr, 'name');
  }

  render() {
    const {classes, zones, processedZones, checkedSectors} = this.props;
    const newZones = zones.map((itemZone) => {
      itemZone.zones = itemZone.zones.filter(itemZones => itemZones.id !== '5de45466c280a473b4ff8488');
      return itemZone;
    });
    console.log('zones', newZones);
    const {openedCities, openedZones} = this.state;
    return (
      <List component="nav" className={classes.zonesPadding}>
        {this.sortName(newZones).map((city, cityIndex) => {
          return (
            <div key={`zone_${cityIndex}`}>
              <ListItem button onClick={() => this.onCollapseCities(city.id)}>
                {processedZones
                .filter(item => checkedSectors.includes(item.sector))
                .some(item => item.city === city.id) && (
                  <ListItemIcon>
                    <CheckRoundedIcon style={{color: 'green'}} />
                  </ListItemIcon>
                )}
                <ListItemText className={classes.collapsableLevel} inset primary={city.name} />
                {openedCities.includes(city.id) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={openedCities.includes(city.id)}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="nav"
                  className={`${classes.zonesPadding} listPadding`}
                >
                  {this.sortName(city.zones).map((zone, zoneIndex) => {
                    return (
                      <div key={`zone_${cityIndex}_${zoneIndex}`}>
                        <ListItem
                          button
                          onClick={() => this.onCollapseZones(zone.id)}
                        >
                          {processedZones
                          .filter(item => checkedSectors.includes(item.sector))
                          .some(item => item.zone === zone.id) && (
                            <ListItemIcon>
                              <CheckRoundedIcon style={{color: 'green'}} />
                            </ListItemIcon>
                          )}
                          <ListItemText className={classes.collapsableLevel} inset primary={zone.name} />
                          {openedZones.includes(zone.id) ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </ListItem>
                        <Collapse
                          in={openedZones.includes(zone.id)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div">
                            {this.sortName(zone.sectors).map((sector, sectorIndex) => {
                              return (
                                <ListItem
                                  button
                                  className={`${classes.zonesPadding} listPadding`}
                                  key={`zone_${cityIndex}_${zoneIndex}_${sectorIndex}`}
                                  onClick={() => this.handleCheckSector(sector.id)}
                                >
                                  <Checkbox
                                    checked={checkedSectors.includes(sector.id)}
                                    color="secondary"
                                  />
                                  <ListItemText inset primary={sector.name} />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Collapse>
                      </div>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          );
        })}
      </List>
    );
  }
}

CollapsableZones.propTypes = {
  classes: PropTypes.object.isRequired,
  onCheckSector: PropTypes.func.isRequired,
  getFormattedZones: PropTypes.func.isRequired,
  setCheckedSectors: PropTypes.func.isRequired,
  zones: PropTypes.array.isRequired,
  processedZones: PropTypes.array.isRequired,
  checkedSectors: PropTypes.array.isRequired
};

const getProcessedZones = (array) => {
  const value = [];
  array.map(city => city.zones.map((zone) => {
    if (zone.id !== '5de45466c280a473b4ff8488') {
      zone.sectors.map((sector) => {
        value.push({
          sector: sector.id,
          zone: zone.id,
          city: city.id
        });
      }); 
    }
  }));
  return value;
};

const mapStateToProps = (state) => {
  const {zone} = state;
  return {
    checkedSectors: zone.checkedSectors,
    zones: zone.formattedZones,
    processedZones: getProcessedZones(zone.formattedZones)
  };
};

const mapDispatchToProps = {
  getFormattedZones,
  setCheckedSectors
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CollapsableZones));
