const apiHost = process.env.REACT_APP_API_HOST;

// export const apiHost = 'http://192.168.100.9:5000/';

export const AREA_IDENTIFIER = process.env.REACT_APP_AREA_IDENTIFIER || 'area_1550604885999';

export const ZONE_IDENTIFIER = process.env.REACT_APP_ZONE_IDENTIFIER || 'zone_1551401186572';

export const REACT_APP_WELCOME_EMAIL_TEMPLATE = process.env.REACT_APP_WELCOME_EMAIL_TEMPLATE || '5cacfa7364f9b14728435184';

export const API_KEY = process.env.REACT_APP_API_KEY || 'dsuLtZY6gsmEyxzxUGGFyvqSN9w6SnsZqFuxDxzPNeheALThQHCVfcLUx5fp9vfR';

export const fbId = '1116139335089950';

export const dateFormat = 'YYYY-MM-DD';

export const copyObject = obj => JSON.parse(JSON.stringify(obj));

export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isPhone(phone) {
  const re = /^\d*$/;
  return re.test(String(phone));
}

export function axiosMaker(method, url, data = null, token) {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'api-key': API_KEY
  };
  if (token) {
    // eslint-disable-next-line no-undef
    headers['x-access-token'] = localStorage.token;
  }
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers
  };
  if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && data !== null) {
    options.data = data;
  }
  return options;
}

export function axiosFormData(method, url, formData, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
    'api-key': API_KEY
  };
  if (token) {
    // eslint-disable-next-line no-undef
    headers['x-access-token'] = localStorage.token;
  }
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers,
    data: formData
  };
  return options;
}

export const errorMessage = (error) => {
  // eslint-disable-next-line no-console
  console.log('error: ', error);
  if (error) {
    const {status, data} = error;
    switch (status) {
    case 400:
      return data.errors.map(obj => obj.message).join(',');
    case 401:
      return 'No has iniciado sesión';
    case 403:
      return 'No tienes permiso para acceder a este recurso';
    case 404:
      return 'No se ha encontrado el recurso';
    case 405:
      return 'No existe el recurso';
    default:
      return 'Ha ocurrido un error';
    }
  }
  return 'Ha ocurrido un error';
};

export const end = {
  input_type: 'final',
  title: 'Gracias, en un momento un asesor se pondrá en contacto contigo',
  options: {
    type: 'final'
  }
};

export const sortObjectByProperty = (array, property, type) => {
  return array.sort((a, b) => {
    const textA = a[property];
    const textB = b[property];
    if (type === 'desc') return textA < textB ? 1 : textA > textB ? -1 : 0;

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};
