import {combineReducers} from 'redux';
import wizardReducer from './wizard';
import zoneReducer from './zone';

export const rootReducer = combineReducers({
  wizard: wizardReducer,
  zone: zoneReducer
});

export default rootReducer;
