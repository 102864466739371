import * as wizardActions from '../actions/types/wizard';

const initialState = {
  parameters: [],
  currentAdvance: [],
  currentAdvanceCustom: [],
  sending: false,
  error: null,
  message: '',
  leadId: null,
  name: '',
  email: '',
  activeStep: 0
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, parameters, message, leadId, activeStep, currentAdvance, currentAdvanceCustom, name, email} = action.payload;
  switch (action.type) {
  case wizardActions.GET_WIZARD_SENDING:
    return {...state, sending: true};
  case wizardActions.GET_WIZARD_SUCCESS:
    return {...state, sending: false, parameters, message: 'Se ha obtenido parámetros satisfactoriamente'};
  case wizardActions.GET_WIZARD_FAILED:
    return {...state, sending: false, error, message, parameters: []};
  case wizardActions.CREATE_LEAD_SENDING:
    return {...state, sending: true};
  case wizardActions.CREATE_LEAD_SUCCESS:
    return {...state, sending: false, message: 'Se ha creado el lead satisfactoriamente', leadId};
  case wizardActions.CREATE_LEAD_FAILED:
    return {...state, sending: false, error, message, leadId: null};
  case wizardActions.ADD_PREFERENCE_SENDING:
    return {...state, sending: false};
  case wizardActions.ADD_PREFERENCE_SUCCESS:
    return {...state, sending: false, message: 'Se ha agregado la preferencia al lead satisfactoriamente', activeStep, currentAdvance, currentAdvanceCustom, name, email};
  case wizardActions.ADD_PREFERENCE_FAILED:
    return {...state, sending: false, error, message};
  case wizardActions.REGISTER_LEAD_SENDING:
    return {...state, sending: false};
  case wizardActions.REGISTER_LEAD_SUCCESS:
    return {...state, sending: false, message: 'Se ha agregado datos de cliente al lead satisfactoriamente', activeStep, currentAdvance, currentAdvanceCustom, name, email};
  case wizardActions.REGISTER_LEAD_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
