import * as zoneActions from '../actions/types/zone';

const initialState = {
  formattedZones: [],
  checkedSectors: [],
  sending: false,
  error: null,
  message: ''
};
  
export default (state, action) => {
  if (state === undefined) {    
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, message, formattedZones, checkedSectors} = action.payload;
  switch (action.type) {
  case zoneActions.GET_FORMATTED_ZONES_SENDING:
    return {...state, sending: true};
  case zoneActions.GET_FORMATTED_ZONES_SUCCESS:
    return {...state, sending: false, formattedZones, message: 'Se han obtenido las zonas satisfactoriamente'};
  case zoneActions.GET_FORMATTED_ZONES_FAILED:
    return {...state, sending: false, error, message, formattedZones: []};
  case zoneActions.SET_CHECKED_SECTORS:
    return {...state, checkedSectors};
  default:
    return state;
  }
};
