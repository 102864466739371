import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Slider from '@material-ui/lab/Slider';
import {styles} from './styles';

export class Range extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: 0
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {answers, currentAnswer} = this.props;
    const min = parseFloat(answers[0].min_val);
    const max = parseFloat(answers[0].max_val);
    this.setState({answer: (max + min) / 2});
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({answer});
      }
    }
  }

  handleChange(event, answer) {
    this.setState({answer});
    this.props.updateAnswer([], answer);
  }

  render() {
    const {classes, answers} = this.props;
    const min = answers[0].min_val;
    const max = answers[0].max_val;
    return (
      <div>
        <Typography className={classes.title}>{this.state.answer}</Typography>
        <Slider
          classes={{container: classes.slider}}
          min={min}
          max={max}
          step={1}
          value={this.state.answer}
          onChange={this.handleChange}
        />
        <Toolbar className={classes.toolbar}>
          <Typography>{min}</Typography>
          <div className={classes.separator} />
          <Typography>{max}</Typography>
        </Toolbar>
      </div>
    );
  }
}

Range.propTypes = {
  classes: PropTypes.object.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    min_val: PropTypes.number.isRequired,
    max_val: PropTypes.number.isRequired,
    answerId: PropTypes.string.isRequired
  })).isRequired
};

export default withStyles(styles)(Range);
