import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {InlineDatePicker} from 'material-ui-pickers';
import {styles} from './styles';
import {dateFormat} from '../../../utils/utils';

export class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: [moment(), moment()]
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({answer});
      }
    }
  }

  handleDateChange(date, index) {
    const answer = this.state.answer;
    answer[index] = date;
    this.setState({answer});
    this.props.updateAnswer([], [moment(answer[0]).format(dateFormat), moment(answer[1]).format(dateFormat)]);
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <InlineDatePicker 
          value={this.state.answer[0]} 
          label="Desde"
          id="dateFrom"
          fullWidth
          onChange={date => this.handleDateChange(date, 0)} 
          maxDate={moment(this.state.answer[1])}
          format={dateFormat}
          className={classes.datePicker}
        />
        <br />
        <InlineDatePicker 
          label="Hasta"
          id="endDate"
          fullWidth
          value={this.state.answer[1]} 
          onChange={date => this.handleDateChange(date, 1)} 
          minDate={moment(this.state.answer[0])} 
          format={dateFormat}
          className={classes.datePicker}
        />
      </div>
    );
  }
}
DateRange.propTypes = {
  classes: PropTypes.object.isRequired,
  updateAnswer: PropTypes.func.isRequired
};

export default withStyles(styles)(DateRange);
