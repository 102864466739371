/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {styles} from './styles';
import {AREA_IDENTIFIER, ZONE_IDENTIFIER, copyObject} from '../../../utils/utils';

export class DoubleCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullAnswers: [],
      answerId: []
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {answers, identifier} = this.props;
    if (identifier === AREA_IDENTIFIER) {
      answers.unshift(answers[answers.length - 1]);
      answers.pop();
    } else if (identifier === ZONE_IDENTIFIER) {
      answers.sort((a, b) => {
        const textA = a.value;
        const textB = b.value;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      const parentAnswers = answers.filter(obj => obj.father === null && obj.active === true);
      const fullAnswers = parentAnswers.map((parent) => {
        const possibleAnswers = answers.filter((obj) => {
          if (obj.active === true && obj.father === parent.catId) {
            return true;
          }
          return false;
        });
        parent.sons = possibleAnswers;
        return parent;
      });
      this.setState({fullAnswers});
    }
  }

  componentDidMount() {
    const {currentAnswerCustom} = this.props;
    if (currentAnswerCustom) {
      const {answerId, fullAnswers} = currentAnswerCustom;
      if (answerId.length > 0) {
        this.setState({answerId});
      }
      if (fullAnswers) {
        this.setState({fullAnswers});
      }
    }
  }

  handleChange(value, options, answerIndex) {
    let {fullAnswers} = this.state;
    const {answerId} = this.state;
    if (answerId.includes(value)) {
      const index = answerId.indexOf(value);
      if (index > -1) {
        answerId.splice(index, 1);
        if (options.father === null) {
          for (let i = 0; i < options.sons.length; i += 1) {
            const son = options.sons[i];
            const indxOf = answerId.indexOf(son.answerId);
            if (indxOf > -1) {
              answerId.splice(indxOf, 1);
            }
          }
          fullAnswers = fullAnswers.filter(obj => obj.father !== options.catId);
          this.setState({fullAnswers});
        }
      }
    } else {
      answerId.push(value);
      if (options.father === null) {
        options.sons.forEach((son, indexSon) => {
          fullAnswers.splice((answerIndex + indexSon + 1), 0, son);
          answerId.push(son.answerId);
        });
        this.setState({fullAnswers});
      }
    }
    this.props.updateAnswerCustom(copyObject(answerId), fullAnswers);

    this.setState({answerId});

    const newAnswerId = answerId.filter((obj) => {
      for (let i = 0; i < fullAnswers.length; i += 1) {
        const actualAnswer = fullAnswers[i];
        if (obj === actualAnswer.answerId) {
          if (actualAnswer.father === null) {
            return false;
          }
        }
      }
      return true;
    });
    this.props.updateAnswer(newAnswerId, '');
  }

  renderAnswers() {
    const {fullAnswers} = this.state;
    return fullAnswers.map((options, index) => {
      return (
        <FormControlLabel
          key={index}
          control={(
            <Checkbox
              className="checkBoxInput"
              checked={!!this.state.answerId.includes(options.answerId)}
              onChange={() => this.handleChange(options.answerId, options, index)}
              value={options.answerId}
            />
          )}
          style={options.father !== null ? {marginLeft: 30} : {marginLeft: 0}}
          label={options.value}
        />
      );
    });
  }

  render() {
    return (
      <FormGroup>
        {this.renderAnswers()}
      </FormGroup>
    );
  }
}
DoubleCheckList.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    answerId: PropTypes.string.isRequired
  })).isRequired,
  currentAnswerCustom: PropTypes.object,
  identifier: PropTypes.string,
  updateAnswer: PropTypes.func.isRequired,
  updateAnswerCustom: PropTypes.func.isRequired
};

export default withStyles(styles)(DoubleCheckList);
