import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {styles} from './styles';

export class CustomTextBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({answer});
      }
    }
  }

  handleChange(e) {
    const answer = e.target.value;
    this.setState({answer});
    this.props.updateAnswer([], answer);
  }

  render() {
    return (
      <TextField
        fullWidth
        label={this.props.placeholder}
        value={this.state.answer}
        multiline
        rowsMax={5}
        onChange={this.handleChange}
        margin="normal"
        variant="outlined"
      />
    );
  }
}
CustomTextBox.propTypes = {
  classes: PropTypes.object.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default withStyles(styles)(CustomTextBox);
