import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {styles} from './styles';
import {AREA_IDENTIFIER, ZONE_IDENTIFIER} from '../../../utils/utils';

export class CheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: []
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {answers, identifier} = this.props;
    if (identifier === AREA_IDENTIFIER) {
      console.log('identifier: ', identifier);
      answers.unshift(answers[answers.length - 1]);
      answers.pop();
    } else if (identifier === ZONE_IDENTIFIER) {
      answers.sort((a, b) => {
        const textA = a.value;
        const textB = b.value;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
  }

  componentDidMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answerId} = currentAnswer;
      if (answerId.length > 0) {
        this.setState({answerId});
      }
    }
  }

  handleChange(value) {
    const {answerId} = this.state;
    if (answerId.includes(value)) {
      const index = answerId.indexOf(value);
      if (index > -1) {
        answerId.splice(index, 1);
      }
    } else {
      answerId.push(value);
    }
    this.setState({answerId});
    this.props.updateAnswer(answerId, '');
  }

  renderAnswers() {
    const activeAnswers = this.props.answers.filter(obj => obj.active === true);
    return activeAnswers.map((options, index) => {
      return (
        <FormControlLabel
          key={index}
          control={(
            <Checkbox
              className="checkBoxInput"
              checked={!!this.state.answerId.includes(options.answerId)}
              onChange={e => this.handleChange(options.answerId)}
              value={options.answerId}
            />
          )}
          label={options.value}
        />
      );
    });
  }

  render() {
    return (
      <FormGroup>
        {this.renderAnswers()}
      </FormGroup>
    );
  }
}
CheckList.propTypes = {
  classes: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    answerId: PropTypes.string.isRequired
  })).isRequired,
  updateAnswer: PropTypes.func.isRequired
};

export default withStyles(styles)(CheckList);
