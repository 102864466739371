import {createMuiTheme} from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00af9a',
      contrastText: '#fff'
    },
    secondary: {
      main: '#00363a'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Poppins',
      'Roboto'
    ].join(',')
  }
});

export default theme;
