import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {InlineDatePicker} from 'material-ui-pickers';
import {styles} from './styles';
import {dateFormat} from '../../../utils/utils';

export class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      placeholder: ''
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStartOver = this.handleStartOver.bind(this);
  }

  componentDidMount() {
    const {isBirthdate, content, currentAnswer} = this.props;
    this.handleStartOver(isBirthdate, content);  
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({date: answer});
      }
    }
  }

  handleDateChange(date) {
    this.setState({date});
    this.props.updateAnswer([], moment(date).format(dateFormat));
  }

  handleStartOver(isBirthdate, content) {
    if (isBirthdate === true) {
      const date = null;
      const placeholder = '';
      this.setState({placeholder, date});
    } else {
      const placeholder = content[0].date;
      this.setState({placeholder, date: new Date()});
    }
  }

  render() {
    const {classes, isBirthdate} = this.props;
    const {date, placeholder} = this.state;
    return isBirthdate === true ? (
      <InlineDatePicker 
        value={date} 
        fullWidth
        onChange={this.handleDateChange} 
        disableFuture
        clearable
        views={['year', 'month', 'day']}
        openToYearSelection
        format={dateFormat}
        autoOk
        className={classes.datePicker}
      />
    )
      : (
        <InlineDatePicker 
          value={date} 
          fullWidth
          clearable
          onChange={this.handleDateChange} 
          label={placeholder}
          format={dateFormat}
          className={classes.datePicker}
        />
      );
  }
}
DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  isBirthdate: PropTypes.bool.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    answerId: PropTypes.string
  })),
  updateAnswer: PropTypes.func.isRequired
};

export default withStyles(styles)(DatePicker);
