export const styles = theme => ({
  root: {
    width: '100%',
    padding: 0
  },
  progress: {
    width: '100%'
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
      borderWidth: 5
    }
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
      borderWidth: 5
    }
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: '#bababa',
      borderWidth: 5
    }
  },
  connectorLine: {
    transition: theme.transitions.create('border-color')
  },
  check: {
    display: 'block',
    color: theme.palette.primary.main,
    marginLeft: 2
  },
  home: {
    display: 'block',
    color: '#bababa'
  },
  horizontal: {
    paddingLeft: 0,
    paddingRight: 0
  },
  iconContainer: {
    padding: 1
  }
});

export default styles;
