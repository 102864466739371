const displayWidth = window.innerWidth;

export const styles = theme => ({
  logo: {
    width: 50
  },
  slogan: {
    width: 110,
    marginTop: 10,
    float: 'right'
  },
  logos: {
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginLeft: window.innerWidth >= 600 ? '10%' : theme.spacing.unit * 2,
    marginRight: window.innerWidth >= 600 ? '10%' : theme.spacing.unit * 2
  },
  header: {
    zIndex: 100,
    background: '#fff', 
    width: '100%',
    height: 100,
    position: 'fixed',
    top: 0,
    left: 0
  },
  container: {
    margin: '0 auto',
    marginRight: displayWidth > 450 ? '10%' : '0',
    marginLeft: displayWidth > 450 ? '10%' : '0'
  }
});

export default styles;
