/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {styles} from './styles';
import DatePicker from '../../../common/datePicker';
import CheckList from '../../../common/checkList';
import DoubleLevelCheckList from '../../../common/doubleCheckList';
import TextBox from '../../../common/textBox';
import RadioList from '../../../common/radioGroup';
import YesNo from '../../../common/yesNo';
import Range from '../../../common/range';
import DateRange from '../../../common/dateRange';
import ImagePicker from '../../../common/imagePicker';
import ImagePickerMultiple from '../../../common/imagePickerMultiple';
import Register from '../../../common/register';
import CollapsableZones from '../../../common/collapsableZones';
import {isEmail, isPhone, ZONE_IDENTIFIER} from '../../../../utils/utils';
import asesor from '../../../../assets/asesor.png';
import {sendWelcomeEmail, updateMatches, assignLead} from '../../../../actions/wizard';

ReactGA.initialize('UA-125917413-1');
ReactGA.pageview(window.location.pathname + window.location.search);
const client_register = 'client_register';
const final = 'final';

export class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registered: false,
      email: '',
      name: '',
      last_name: '',
      phone: '',
      isFbLogged: false,
      answer: '',
      answerId: [],
      answerIdCustom: [],
      fullAnswers: []
    };
    this.nextQuestion = this.nextQuestion.bind(this);
    this.renderFinalQuestion = this.renderFinalQuestion.bind(this);
    this.renderAnswer = this.renderAnswer.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.updateAnswerCustom = this.updateAnswerCustom.bind(this);
    this.dateAnswer = this.dateAnswer.bind(this);
    this.textAnswer = this.textAnswer.bind(this);
    this.renderQuestionTitle = this.renderQuestionTitle.bind(this);
    this.yesNoAnswer = this.yesNoAnswer.bind(this);
    this.imagePickerUnique = this.imagePickerUnique.bind(this);
    this.imagePickerMultiple = this.imagePickerMultiple.bind(this);
    this.radioGroup = this.radioGroup.bind(this);
    this.checkList = this.checkList.bind(this);
    this.range = this.range.bind(this);
    this.dateRange = this.dateRange.bind(this);
    this.handleFbLogin = this.handleFbLogin.bind(this);
    this.clientRegister = this.clientRegister.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.renderWhiteSpace = this.renderWhiteSpace.bind(this);
    this.validate = this.validate.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onUpdateName = this.onUpdateName.bind(this);
    this.onUpdateLastname = this.onUpdateLastname.bind(this);
    this.onUpdateMail = this.onUpdateMail.bind(this);
    this.onUpdatePhone = this.onUpdatePhone.bind(this);
    this.sendWelcomeEmail = this.sendWelcomeEmail.bind(this);
    this.updateLeadMatches = this.updateLeadMatches.bind(this);
    this.updateZones = this.updateZones.bind(this);
  }

  componentWillMount() {
    const {currentAnswer, question} = this.props;
    if (currentAnswer && question.input_type === 'client_register') {
      this.setState({
        name: currentAnswer.name,
        last_name: currentAnswer.last_name,
        email: currentAnswer.email,
        phone: currentAnswer.phone,
        registered: true
      });
    }
  }

  onUpdateName(name) {
    this.setState({name, registered: false});
  }

  onUpdateLastname(last_name) {
    this.setState({last_name, registered: false});
  }

  onUpdateMail(email) {
    this.setState({email, registered: false});
  }

  onUpdatePhone(phone) {
    if (isPhone(phone)) {
      this.setState({phone, registered: false});
    }
  }

  onRegister() {
    const {email, name, last_name, phone} = this.state;
    this.props.registerUserLead(name, last_name, email, phone);
    ReactGA.event({
      category: 'registro', 
      action: 'envio', 
      label: 'siguiente_lv_1'
    });
  }

  sendWelcomeEmail() {
    const {name, email} = this.props;
    this.props.sendWelcomeEmail(email, name);
  }

  updateLeadMatches() {
    // const {leadId} = this.props;
    // this.props.updateMatches(leadId); // api no funciona
    // this.props.assignLead(leadId);
  }

  nextQuestion() {
    const {question, activeStep} = this.props;
    const {answerId, answer, answerIdCustom, fullAnswers} = this.state;
    this.props.addPreference(question, answerId, answerIdCustom, fullAnswers, answer, activeStep);
    this.setState({answerId: [], answer: ''});
  }

  updateAnswer(answerId, answer) {
    this.setState({answerId, answer});
  }

  updateAnswerCustom(answerIdCustom, fullAnswers) {
    this.setState({answerIdCustom, fullAnswers});
  }

  updateZones(sectors, answers) {
    const answerId = answers
      .filter((item) => sectors.includes(item.id))
      .map(({ answerId }) => answerId);

    this.updateAnswer(answerId, '');
  }

  dateAnswer(isBirthdate) {
    const {currentAnswer} = this.props;
    return (
      <DatePicker 
        isBirthdate={isBirthdate} 
        updateAnswer={this.updateAnswer} 
        currentAnswer={currentAnswer}
        content={this.props.question.options.content}
      />
    );
  }

  textAnswer() {
    const {currentAnswer} = this.props;
    return (
      <TextBox 
        updateAnswer={this.updateAnswer} 
        currentAnswer={currentAnswer}
        placeholder={this.props.question.options.content[0].placeholder}
      />
    );
  }

  yesNoAnswer() {
    const {currentAnswer} = this.props;
    return (
      <YesNo 
        currentAnswer={currentAnswer}
        updateAnswer={this.updateAnswer}
      />
    );
  }

  imagePickerUnique() {
    const {currentAnswer} = this.props;
    return (
      <ImagePicker 
        answers={this.props.question.options.content} 
        currentAnswer={currentAnswer}
        updateAnswer={this.updateAnswer} 
      />
    );
  }

  imagePickerMultiple() {
    const {currentAnswer} = this.props;
    return (
      <ImagePickerMultiple 
        answers={this.props.question.options.content} 
        currentAnswer={currentAnswer}
        updateAnswer={this.updateAnswer} 
      />
    );
  }

  radioGroup() {
    const {currentAnswer} = this.props;
    return (
      <RadioList 
        answers={this.props.question.options.content} 
        currentAnswer={currentAnswer}
        updateAnswer={this.updateAnswer} 
      />
    );
  }

  checkList() {
    const {currentAnswer, question, currentAnswerCustom} = this.props;
    if (question.identifier === ZONE_IDENTIFIER) {
      return (
        <CollapsableZones
          onCheckSector={(value) => this.updateZones(value, this.props.question.options.content)}
        />        
      );
    }
    return (
      <CheckList 
        answers={this.props.question.options.content} 
        currentAnswer={currentAnswer}
        updateAnswer={this.updateAnswer}  
        identifier={question.identifier}
      />
    );
  }

  range() {
    const {currentAnswer} = this.props;
    return (
      <Range 
        currentAnswer={currentAnswer}
        answers={this.props.question.options.content} 
        updateAnswer={this.updateAnswer} 
      />
    );
  }

  dateRange() {
    const {currentAnswer} = this.props;
    return (
      <DateRange 
        currentAnswer={currentAnswer}
        updateAnswer={this.updateAnswer} 
      />
    );
  }

  clientRegister() {
    const {phone, email, name, last_name} = this.state;  
    return (
      <Register 
        handleFbLogin={this.handleFbLogin} 
        isFbLogged={this.state.isFbLogged}
        name={name}
        last_name={last_name}
        email={email}
        phone={phone}
        onUpdateName={this.onUpdateName}
        onUpdateLastname={this.onUpdateLastname}
        onUpdateMail={this.onUpdateMail}
        onUpdatePhone={this.onUpdatePhone}
      />
    );
  }

  handleFbLogin(name, last_name, email) {
    this.setState({isFbLogged: true, name, last_name, email});
    ReactGA.event({
      category: 'registro_fb', 
      action: 'envio', 
      label: 'agregar_tlf_lv2'
    });
  }

  validate() {
    const {question} = this.props;
    const {phone, email, name, last_name, answer, answerId, registered} = this.state;  
    const isRegister = question.input_type === 'client_register';
    if (isRegister) {
      if (!registered) {
        const {isFbLogged} = this.props;  
        if (isFbLogged) {
          if (phone !== '' && phone.length >= 7) return false;
          return true;
        }
                
        if (phone !== '' && email !== '' && name !== '' && last_name && isEmail(email) && phone.length >= 7) return false;
        return true;
      }
            
      return true;
    }
        
    if (answerId.length > 0 || answer !== '') {
      return false;
    }
    return true;
  }

  renderAnswer() {
    const question_type = this.props.question.input_type;
    switch (question_type) {
    case 'client_register':
      return this.clientRegister();
    case 'final':
      return '';
    case 'date':
      return this.dateAnswer(false);
    case 'birth_date':
      return this.dateAnswer(true);
    case 'text_box':
      return this.textAnswer();
    case 'yes_no':
      return this.yesNoAnswer();
    case 'image_unique':
      return this.imagePickerUnique();
    case 'image_multiple':
      return this.imagePickerMultiple();
    case 'radio_group':
      return this.radioGroup();
    case 'check_list':
      return this.checkList();
    case 'range':
      return this.range();
    case 'date_range':
      return this.dateRange();
    default:
      return this.textAnswer();
    }
  }

  renderButton() {
    const {classes, question} = this.props;
    const {isFbLogged} = this.state;
    const isRegister = question.input_type === client_register;
    const finalQuestion = question.input_type === final;
    // eslint-disable-next-line no-nested-ternary
    const buttonText = isRegister ? isFbLogged ? 'Agregar Teléfono' : 'Siguiente' : 'Siguiente';
    if (!finalQuestion) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={isRegister ? this.onRegister : this.nextQuestion}
          disabled={this.validate()}
        >
          {buttonText}
        </Button>
      );
    }
    return null;
  }

  renderQuestionTitle() {
    const {classes, question} = this.props;
    const {isFbLogged} = this.state;
    if (isFbLogged === false) {
      if (question.input_type === client_register) {
        return ( 
          <div>
            <Typography variant="h4" className={classes.superTitle}>
              Bienvenido,
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {question.title}
            </Typography>
          </div>
        );
      }
      if (question.input_type !== final) {
        if (question.title !== '') {
          return ( 
            <Typography variant="subtitle1" style={{fontWeight: 700}}>
              {question.title}
            </Typography>
          );
        }
      }
    }
    return null;
  }

  renderFinalQuestion() {
    const {classes, name} = this.props;
    ReactGA.event({
      category: 'registro', 
      action: 'envio', 
      label: 'completo'
    });
    // this.sendWelcomeEmail();
    this.updateLeadMatches();
    return (
      <div className={classes.questionContainer}> 
        <div className={classes.finalCol}>
          <img src={asesor} alt="asesor" className={classes.asesor} />
        </div>
        <div className={classes.finalCol}>
          <Typography variant="h4" className={classes.superTitle}>
                        ¡Listo!
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {`Muchas gracias ${name} por completar tu perfil.`}
          </Typography>
          <br />
          <Typography variant="subtitle1" className={classes.subtitle}>
                        Nuestra plataforma ha pre-seleccionado las mejores opciones para ti y pronto tu asesor asignado se pondrá en contacto contigo.
          </Typography>
          <br />
          <Typography variant="subtitle1" className={classes.subtitle}>
                        Si tienes cualquier inquietud, por favor visita nuestra sección de preguntas frecuentes.
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            href="http://trivo.com.ec/que-es-trivo/"
          >
                        Ver preguntas frecuentes
          </Button> */}
        </div>
      </div>
    );
  }

  renderWhiteSpace() {
    const {classes, currentAnswer} = this.props;
    if (!currentAnswer) {
      return (
        <div className={classes.whiteSpace}>&nbsp;</div>
      );
    }
    return null;
  }

  render() {
    const {classes, question, currentAnswer} = this.props;
    return question.input_type === final ? this.renderFinalQuestion() : (
      <div className={currentAnswer ? classes.questionAnswered : classes.questionContainer}>
        {this.renderQuestionTitle()}
        <div className={classes.answerContainer}>
          {this.renderAnswer()}
        </div>
        {this.renderButton()}
        {this.renderWhiteSpace()}
      </div>
    );
  }
}
Question.propTypes = {
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  currentAnswer: PropTypes.object,
  currentAnswerCustom: PropTypes.object,
  name: PropTypes.string,
  email: PropTypes.string,
  isFbLogged: PropTypes.bool,
  updateMatches: PropTypes.func.isRequired,
  addPreference: PropTypes.func.isRequired,
  registerUserLead: PropTypes.func.isRequired,
  sendWelcomeEmail: PropTypes.func.isRequired,
  leadId: PropTypes.string
};

const mapDispatchToProps = {
  sendWelcomeEmail,
  updateMatches,
  assignLead
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Question));
