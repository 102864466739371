import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import Wizard from '../components/wizard';

// eslint-disable-next-line no-shadow
const PublicRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => <Component {...props} />} />
  );
};

export default class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute path="/:attributeId/:answerId" component={Wizard} />
          <Route path="/" exact render={() => <h6>Error 404 No Encontrado</h6>} />
          <Route render={() => <h6>Error 404 No Encontrado</h6>} />
        </Switch>
      </BrowserRouter>
    );
  }
}

PublicRoute.propTypes = {
  component: PropTypes.func
};
