const displayWidth = window.innerWidth;
const size = displayWidth > 450 ? 160 : displayWidth > 340 ? 140 : 120;

export const styles = theme => ({
  image: {
    width: '100%',
    height: size,
    cursor: 'pointer'
  },
  cell: {
    height: displayWidth > 450 ? 210 : displayWidth > 340 ? 160 : 140,
    marginRight: displayWidth > 450 ? 20 : displayWidth > 340 ? 18 : 16,
    width: size,
    display: 'inline-block'
  },
  gridListTile: {
    listStyleType: 'none'
  },
  iconContainer: {
    background: '#fff',
    borderRadius: 5,
    marginRight: 3,
    marginTop: 2
  },
  gridTile: {
    background: 'transparent',
    cursor: 'pointer'
  },
  icon: {
    color: '#00363a',
    padding: 0
  },
  titleBar: {
    background:
    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, '
    + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  }
});
