/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import axios from 'axios';
import * as wizardActions from './types/wizard';
import {axiosMaker, errorMessage, end, REACT_APP_WELCOME_EMAIL_TEMPLATE} from '../utils/utils';

const company = '5e793bfe142fff7b1494b7e6';

export const getWizard = () => async (dispatch) => {
  dispatch({
    type: wizardActions.GET_WIZARD_SENDING,
    payload: {}
  });
  try {
    const response = await axios(axiosMaker('GET', `attributes/wizard/${company}`, null, false));
    let parameters = response.data.results;
    parameters = parameters.filter(item => item.identifier !== 'amenidades_1551338791659');
    parameters.push(end);
    console.log(parameters);
    
    return dispatch({
      type: wizardActions.GET_WIZARD_SUCCESS,
      payload: {
        parameters
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: wizardActions.GET_WIZARD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createLead = (user, attributeId, answerId) => async (dispatch) => {
  const preferences = {
    attributeId,
    answerId: [answerId],
    answer: ''
  };
  dispatch({
    type: wizardActions.CREATE_LEAD_SENDING,
    payload: {}
  });
  try {
    const data = {
      user,
      preferences,
      // origin: 'Web - Wizard'
      origin: '66298395a938dd272872cfaf', // identificador para web-wizard
      company: '5e793bfe142fff7b1494b7e6' // Trivo
    };
    const response = await axios(axiosMaker('POST', 'leads', data, false));
    return dispatch({
      type: wizardActions.CREATE_LEAD_SUCCESS,
      payload: {
        leadId: response.data.id
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: wizardActions.CREATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const registerUserLead = (id, user, activeStep, currentAdvance, currentAdvanceCustom, name, email) => async (dispatch) => {
  dispatch({
    type: wizardActions.REGISTER_LEAD_SENDING,
    payload: {}
  });
  try {
    // await axios(axiosMaker('PUT', `leads/${id}/updateUser`, data, false));
    activeStep++;
    return dispatch({
      type: wizardActions.REGISTER_LEAD_SUCCESS,
      payload: {
        activeStep,
        currentAdvance,
        currentAdvanceCustom,
        name,
        email
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: wizardActions.REGISTER_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const addPreference = (id, data, activeStep, currentAdvance, currentAdvanceCustom, name, email) => async (dispatch) => {
  dispatch({
    type: wizardActions.ADD_PREFERENCE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', `leads/${id}/addPreference`, data, false));
    activeStep++;
    return dispatch({
      type: wizardActions.ADD_PREFERENCE_SUCCESS,
      payload: {
        activeStep,
        currentAdvance,
        currentAdvanceCustom,
        name,
        email
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: wizardActions.ADD_PREFERENCE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const sendWelcomeEmail = (email, name) => async (dispatch) => {
  dispatch({
    type: wizardActions.WELCOME_EMAIL_SENDING,
    payload: {}
  }); 
  try {
    const data = {
      templateId: REACT_APP_WELCOME_EMAIL_TEMPLATE,
      to: email,
      data: {
        texto_nombre: name
      }
    };
    await axios(axiosMaker('POST', 'users/sendEmailTemplate', data, false));
    return dispatch({
      type: wizardActions.WELCOME_EMAIL_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: wizardActions.WELCOME_EMAIL_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateMatches = id => async (dispatch) => {
  dispatch({
    type: wizardActions.UPDATE_MATCHES_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PUT', `leads/${id}/updateMatches`, {}, false));
    return dispatch({
      type: wizardActions.UPDATE_MATCHES_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: wizardActions.UPDATE_MATCHES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const assignLead = id => async (dispatch) => {
  dispatch({
    type: wizardActions.UPDATE_MATCHES_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PUT', `leads/${id}/assignLead`, {}, false));
    return dispatch({
      type: wizardActions.UPDATE_MATCHES_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: wizardActions.UPDATE_MATCHES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
