const headerHeight = 130;
const deviceWidth = window.innerWidth;
const columnWidth = ((deviceWidth * 0.8) - 100) / 2;

export const styles = theme => ({
  questionContainer: {
    marginTop: theme.spacing.unit + headerHeight,
    padding: theme.spacing.unit * 1.5,
    minHeight: window.innerHeight
  },
  questionAnswered: {
    marginTop: theme.spacing.unit + headerHeight,
    padding: theme.spacing.unit * 1.5
  },
  answerContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit
  },
  appBarSpace: {
    width: '100%',
    height: 70
  },
  whiteSpace: {
    width: '100%',
    height: deviceWidth < 450 ? 300 : 0
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  subtitle: {
    color: '#787878'
  },
  superTitle: {
    marginBottom: theme.spacing.unit
  },
  asesor: {
    width: 200,
    display: 'table',
    margin: '0 auto',
    marginBottom: 20
    
  },
  finalCol: {
    display: deviceWidth > 450 ? 'inline-block' : 'block',
    width: deviceWidth > 450 ? columnWidth : 250,
    verticalAlign: 'top',
    margin: '0 auto'
  }
});

export default styles;
