import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {styles} from './styles';

export class RadioList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: ''
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answerId} = currentAnswer;
      if (answerId.length > 0) {
        this.setState({answerId: answerId[0]});
      }
    }
  }

  handleChange(event) {
    const answerId = event.target.value;
    this.setState({answerId});
    const arr = [];
    arr.push(answerId);
    this.props.updateAnswer(arr, '');
  }

  renderAnswers() {
    const activeAnswers = this.props.answers.filter(obj => obj.active === true);
    return activeAnswers.map((options, index) => {
      return (
        <FormControlLabel
          key={index}
          control={<Radio />}
          label={options.value}
          value={options.answerId}
        />
      );
    });
  }

  render() {
    const {classes} = this.props;
    return (
      <RadioGroup
        id="radioInput"
        className={classes.group}
        value={this.state.answerId}
        onChange={this.handleChange}
      >
        {this.renderAnswers()}
      </RadioGroup>
    );
  }
}

RadioList.propTypes = {
  classes: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    answerId: PropTypes.string.isRequired
  })).isRequired,
  updateAnswer: PropTypes.func.isRequired
};

export default withStyles(styles)(RadioList);
