import React, {Component} from 'react';
import FacebookLogin from 'react-facebook-login';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import {styles} from './styles';
import {fbId, isPhone} from '../../../utils/utils';
import RegisterForm from './registerForm';

export class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.responseFacebook = this.responseFacebook.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderPhoneText = this.renderPhoneText.bind(this);
    this.renderFacebookButton = this.renderFacebookButton.bind(this);
  }

  responseFacebook(response) {
    if (response) {
      const {first_name, last_name, email} = response;
      if (first_name && last_name && email) {
        this.props.handleFbLogin(first_name, last_name, email);
      }
    }
  }

  renderFacebookButton() {
    const {classes} = this.props;
    return (
      <div>
        <FacebookLogin
          appId={fbId}
          fields="name,email,birthday, first_name,last_name"
          scope="public_profile,email"
          cssClass={classes.facebookButton}
          icon="fa-facebook"
          textButton="&nbsp; &nbsp; Continuar con Facebook"
          onClick={this.facebookClick}
          callback={this.responseFacebook} 
          disableMobileRedirect
          id="facebookButton"
        />
        <Divider variant="middle" />
      </div>
    );
  }

  renderTitle() {
    const {isFbLogged, name} = this.props;
    const title = isFbLogged ? `Hola ${name}, ¿Cómo podemos contactarte?` : '';
    return (
      <Typography variant="body1">
        {title}
      </Typography>
    );
  }

  renderPhoneText() {
    const {classes, phone} = this.props;
    return (
      <TextField 
        required 
        value={phone} 
        onChange={e => isPhone(e.target.value) && this.props.onUpdatePhone(e.target.value)} 
        fullWidth 
        inputProps={{
          maxLength: 12
        }}
        label="Teléfono" 
        error={phone !== '' && phone.length < 7}
        autoFocus
        className={classes.input} 
      />
    );
  }

  render() {
    const {name, last_name, email, phone, isFbLogged} = this.props;
    return (
      <div>
        {this.renderTitle()}
        {
          isFbLogged
            && this.renderPhoneText()
           // : this.renderFacebookButton()
        }
        {
          isFbLogged === false
                        && (
                          <RegisterForm 
                            name={name}
                            last_name={last_name}
                            email={email}
                            phone={phone}
                            onUpdateName={this.props.onUpdateName}
                            onUpdateLastname={this.props.onUpdateLastname}
                            onUpdateMail={this.props.onUpdateMail}
                            onUpdatePhone={this.props.onUpdatePhone}
                          />
                        )
        }
      </div>
    );
  }
}
Register.propTypes = {
  classes: PropTypes.object.isRequired,
  handleFbLogin: PropTypes.func.isRequired,
  isFbLogged: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  last_name: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  onUpdateName: PropTypes.func.isRequired,
  onUpdateLastname: PropTypes.func.isRequired,
  onUpdateMail: PropTypes.func.isRequired,
  onUpdatePhone: PropTypes.func.isRequired
};

export default (withStyles(styles)(Register));
