/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getWizard, createLead, addPreference, registerUserLead} from '../../actions/wizard';
import Stepper from './stepper';
import {styles} from './styles';
import logo from '../../assets/logotrivo.png';
import slogan from '../../assets/slogan.png';
import QuestionContainer from './questionContainer';
import {CREATE_LEAD_SUCCESS} from '../../actions/types/wizard';

export class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.registerUserLead = this.registerUserLead.bind(this);
    this.addPreference = this.addPreference.bind(this);
    this._pageScroller = null;
  }

  componentWillMount() {
    const {props} = this;
    if (props) {
      const {match} = props;
      if (match) {
        const {params} = match;
        if (params) {
          const {attributeId, answerId} = params;
          if (attributeId !== 'reason_1550604396439') {
            window.location.replace('/reason_1550604396439/reason_1550604396439_1');
          } 
          if (answerId === 'reason_1550604396439_1' || answerId === 'reason_1550604396439_2') {
          } else {
            window.location.replace('/reason_1550604396439/reason_1550604396439_1');
          }
        }
      }
    }
    this.props.getWizard();
  }
  
  addPreference(question, answerId, answerIdCustom, fullAnswers, answer, activeStep) {
    const {leadId, currentAdvance, currentAdvanceCustom, name, email} = this.props;
    const data = {
      attributeId: question.identifier,
      answerId,
      answer
    };
    const customData = {
      attributeId: question.identifier,
      answerId: answerIdCustom,
      answer,
      fullAnswers
    };
    currentAdvance[activeStep] = data;
    currentAdvanceCustom[activeStep] = customData;
    this.props.addPreference(leadId, data, activeStep, currentAdvance, currentAdvanceCustom, name, email);
  }

  registerUserLead(name, lastname, email, phone) {
    const {activeStep, currentAdvance, currentAdvanceCustom} = this.props;
    const {attributeId, answerId} = this.props.match.params;
    const user = {name, lastname, email, phone};
    if (attributeId && answerId) {
      this.props.createLead(user, attributeId, answerId).then((result) => {
        if (result.type === CREATE_LEAD_SUCCESS) {
          const thisLeadId = result.payload.leadId;
          currentAdvance[0] = user;
          currentAdvanceCustom[0] = user;
          this.props.registerUserLead(thisLeadId, user, activeStep, currentAdvance, currentAdvanceCustom, name, email);
        }
      });
    }
  }
  
  render() {
    const {classes, isLoading, parameters, activeStep, currentAdvance, currentAdvanceCustom, name, email, leadId} = this.props;
    return isLoading === true ? <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} size={50} thickness={7} /> : (
      <div>
        <div className={classes.header}>
          <div className={classes.logos}>
            <img src={logo} alt="logo" className={classes.logo} />
            <img src={slogan} alt="logo" className={classes.slogan} />
          </div>
          <Stepper activeStep={currentAdvance.length} length={parameters.length} />
        </div>
        <div className={classes.container}>
          <QuestionContainer 
            parameters={parameters}
            currentAdvance={currentAdvance}
            currentAdvanceCustom={currentAdvanceCustom}
            activeStep={activeStep}
            addPreference={this.addPreference}
            registerUserLead={this.registerUserLead}
            leadId={leadId}
            name={name}
            email={email}
          />
        </div>
      </div>
    );
  }
}
Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  parameters: PropTypes.array.isRequired,
  currentAdvance: PropTypes.array.isRequired,
  currentAdvanceCustom: PropTypes.array.isRequired,
  getWizard: PropTypes.func.isRequired, 
  addPreference: PropTypes.func.isRequired,
  createLead: PropTypes.func.isRequired,
  registerUserLead: PropTypes.func.isRequired,
  leadId: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  activeStep: PropTypes.number,
  match: PropTypes.object
};
const mapStateToProps = (state) => {
  const {wizard} = state;
  return { 
    isLoading: wizard.sending, 
    error: wizard.error, 
    message: wizard.message,
    parameters: wizard.parameters, 
    currentAdvance: wizard.currentAdvance, 
    currentAdvanceCustom: wizard.currentAdvanceCustom, 
    leadId: wizard.leadId,
    activeStep: wizard.activeStep,
    name: wizard.name,
    email: wizard.email
  };
};

const mapDispatchToProps = {
  getWizard,
  registerUserLead,
  createLead,
  addPreference
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Wizard));
