import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import {styles} from './styles';
import {isEmail} from '../../../../utils/utils';

export class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {classes, name, last_name, email, phone} = this.props;
    return (
      <div>
        <div className={classes.inputContainer}> 
          <TextField 
            required 
            value={name} 
            onChange={e => this.props.onUpdateName(e.target.value)} 
            fullWidth 
            label="Nombre" 
            id="name"
            className={classes.input} 
            onFocus={this.scrollToName}
          />
                      
        </div>
        <div className={classes.inputContainer}>  
          <TextField 
            required 
            value={last_name} 
            onChange={e => this.props.onUpdateLastname(e.target.value)}
            fullWidth 
            label="Apellido" 
            id="lastName"
            className={classes.input} 
            onFocus={this.scrollToLastname}
          />
                     
        </div>
        <div className={classes.inputContainer}>   
          <TextField 
            required 
            value={email} 
            onChange={e => this.props.onUpdateMail(e.target.value)} 
            fullWidth 
            error={email !== '' && !isEmail(email)}
            label="Email" 
            id="email"
            onFocus={this.scrollToEmail}
            className={classes.input}
          />
                    
        </div>
        <div className={classes.inputContainer}>   
          <TextField 
            required 
            value={phone} 
            onChange={e => this.props.onUpdatePhone(e.target.value)} 
            fullWidth 
            inputProps={{
              maxLength: 12
            }}
            error={phone !== '' && phone.length < 7}
            label="Teléfono" 
            id="phone"
            onFocus={this.scrollToPhone}
            className={classes.input}
          />
        </div>
      </div>
    );
  }
}
RegisterForm.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  last_name: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  onUpdateName: PropTypes.func.isRequired,
  onUpdateLastname: PropTypes.func.isRequired,
  onUpdateMail: PropTypes.func.isRequired,
  onUpdatePhone: PropTypes.func.isRequired
};

export default (withStyles(styles)(RegisterForm));
