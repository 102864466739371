/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Element, scroller} from 'react-scroll';
import Question from './question';

export class QuestionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderQuestions = this.renderQuestions.bind(this);
    this.scrollToLastQuestion = this.scrollToLastQuestion.bind(this);
  }

  componentWillMount() {
    this.scrollToLastQuestion();  
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.scrollToLastQuestion();
    }
  }

  scrollToLastQuestion = () => {
    const config = {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: -100
    };
    scroller.scrollTo('currentQuestion', config);
  }

  renderQuestions() {
    const {parameters, currentAdvance, currentAdvanceCustom, name, email, leadId} = this.props;
    let {activeStep} = this.props;
    if (parameters.length > 0) {
      const allQuestions = parameters.map((question, index) => {
        if (index === (currentAdvance.length)) {
          return (
            <Element name="currentQuestion" key={index}>
              <Question 
                activeStep={index}
                name={name}
                email={email}
                leadId={leadId}
                question={question} 
                addPreference={this.props.addPreference}
                registerUserLead={this.props.registerUserLead}
              />
            </Element>
          );
        }
        
        return (
          <Question 
            key={index}
            activeStep={index}
            currentAnswer={currentAdvance[index]}
            currentAnswerCustom={currentAdvanceCustom[index]}
            question={question} 
            addPreference={this.props.addPreference}
            registerUserLead={this.props.registerUserLead}
          />
        );
      });
      activeStep += 1;
      if (activeStep === parameters.length) {
        return allQuestions.slice(activeStep - 1, activeStep);
      }
      return allQuestions.slice(0, (currentAdvance.length + 1));
    }
    return '';
  }

  render() {
    return this.renderQuestions();
  }
}
QuestionContainer.propTypes = {
  parameters: PropTypes.arrayOf(PropTypes.object.isRequired),
  activeStep: PropTypes.number.isRequired,
  currentAdvance: PropTypes.array,
  currentAdvanceCustom: PropTypes.array,
  addPreference: PropTypes.func.isRequired,
  registerUserLead: PropTypes.func.isRequired,
  leadId: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string
};

export default QuestionContainer;
